.e-current-day, .e-active-view .e-tbar-btn-text {
  color: rgb(13, 110, 253) !important;
}

.e-current-date .e-date-header {
  background: rgb(13, 110, 253) !important;
}

.e-tbar-btn:focus {
  box-shadow: none !important;
}

.e-datetime-wrapper, .e-datetime-wrapper input {
  height: 2.5rem !important;
}

#_recurrence_editor.e-editor {
  max-width: 100% !important;
  justify-content: space-between;
}

.e-float-text, .e-week-expander-label, .e-month-expander-label {
  font-size: 0.875rem !important;
  font-family: 'proxima-nova' !important;
  line-height: 1rem !important;
  font-weight: 600 !important;
  top: calc(50% - 0.75rem) !important;
  color: rgb(14, 18, 18) !important;
}

.e-float-text::after, .e-week-expander-label::after, .e-month-expander-label::after {
  content: '*';
  color: rgb(195, 48, 53);
}

#_recurrence_editor.e-form-left, #_recurrence_editor.e-end-on-left {
  width: 15.125rem;
  padding-right: 0px;
}

.e-input-wrapper.e-end-on-left {
  padding-right: 0.5rem !important;
}

#_recurrence_editor .e-input-wrapper input {
  height: 2.5rem !important;
  background-color: rgb(255, 255, 255)!important;
}

.e-input-group.e-input-focus, .e-input-group.e-control-wrapper.e-input-focus {
  border-color: rgb(14, 18, 18) !important;
  box-shadow: none !important
}

.e-list-item {
  padding: 0.5rem 1rem;
}

.e-list-item:hover {
  color: rgb(47,94,85) !important;
  background-color: rgb(224,240,237) !important;
  box-shadow: inset 2px 0px 0px rgb(47,94,85);
}

.e-list-item.e-active {
  background-color: rgb(14, 18, 18) !important;
  color: rgb(255, 255, 255) !important;
}


.e-end-on {
  display: flex;
  justify-content: space-between;
  padding: 0px !important;
  width: 100% !important;
  margin-bottom: 1rem;
}

.e-days, .e-non-week {
  width: 100% !important;
  background-color: rgb(245, 245, 245);
  padding-top: 0.75rem !important;
  padding-left: 1.25rem !important;
  border-radius: 4px;
  margin-bottom: 1.25rem;
}

.e-interval, .e-end-on-date, .e-end-on-count {
  padding-left: 0.5rem !important;
}

.e-recurrence-table {
  width: 65% !important;
}

.e-repeat-content-wrapper {
  width: 100% !important;
}

#_recurrence_editorbutton.e-btn {
  background-color: rgb(213, 213, 213) !important;
}

#_recurrence_editor.e-btn.e-primary.e-active {
  background-color: rgb(79, 153, 240) !important;
  border: none !important;
}

.e-recurrenceeditor .e-recurrence-table.e-month-expand-wrapper td:first-child {
width: 15% !important;
}

.e-radio:checked + label::before {
  background-color: rgb(14, 18, 18) !important;
  border-color: rgb(14, 18, 18) !important;
}

.all-day-event span.e-time-icon, 
.e-resources-row,
.e-description-row,
.e-time-zone-container,
.e-title-location-row {
  display: none !important;
}

.e-dlg-container .e-dlg-header-content,
.e-dlg-container .e-dlg-content,
.e-dlg-container .e-footer-content {
  background-color: rgb(255, 255, 255) !important;
}

.e-dlg-container .e-dlg-header-content {
  padding-top: 1rem !important;
}

#_dialog_wrapper {
  max-height: 43.5625rem !important;
  width: 34.1875rem !important;
  overflow-y: auto;
  background-color: rgb(255, 255, 255) !important;
}

.e-dialog-parent {
  padding-bottom: 1.5rem;
}

.e-dlg-container .e-footer-content {
  display: flex !important;
  height: 4.5rem !important;
}

.e-dlg-container .e-footer-content .e-primary {
  background-color: rgb(14, 18, 18) !important;
  height: 2rem !important;
}


.e-dlg-container .e-footer-content .e-primary:hover {
  background-color: rgb(50, 64, 69) !important;
  box-shadow: 0px 1px 4px rgba(18,21,27,0.15) !important;
}

.e-dlg-container .e-footer-content .e-primary:disabled {
  color: rgb(160, 176, 181) !important;
  background-color: rgb(246, 248, 249) !important;
}

.e-dlg-container .e-footer-content .e-primary:disabled:hover {
  box-shadow: none !important;
}

.e-dlg-container .e-footer-content .e-event-cancel {
  border: none !important;
  height: 2rem !important;
}

.e-checkbox-wrapper .e-frame.e-check, .e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: rgb(14, 18, 18) !important;
  border-color: rgb(14, 18, 18) !important;
}

.shadow-0 {
  box-shadow: 0 0 #0000 !important;
}

.e-all-day-time-zone-row {
  margin-bottom: 1rem;
}

#EditForm legend {
  font-family: 'proxima-nova' !important;
}

.e-popup-footer button:hover, .e-popup-footer button:focus,
.e-footer-content button:hover, .e-footer-content button:focus {
  box-shadow: 0px 1px 4px rgba(18, 21, 27, 0.15) !important;
}

.e-popup-footer button,
.e-footer-content button {
  border-color: transparent !important; 
}

button.e-event-edit {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(14, 18, 18) !important;
  margin-bottom: 0.5rem;
}

button.e-event-edit:hover {
  background-color: rgb(50, 64, 69) !important;
}

button.e-event-delete {
  margin-bottom: 0.5rem;
  order: 1;
}

button.e-event-delete:hover {
  background-color: rgb(246, 248, 249) !important;
  color: rgb(50, 64, 69) !important;
}

#QuickDialog {
  width: 22rem !important;
}

#QuickDialog button.e-quick-dialog-cancel {
  height: 2rem;
}

#QuickDialog .e-footer-content {
  justify-content: end;
  height: auto !important;
  margin-bottom: 1rem;
}

.e-schedule-dialog .e-footer-content {
  border-top: 1px solid rgb(225, 233, 236) !important;
  padding: 1rem !important;
}

#_dialog_wrapper_dialog-header {
  padding-top: 1.5rem !important;
  padding-bottom: 1.25rem !important;
  border-bottom: 1px solid rgb(225, 233, 236);
  border-radius: 0;
}

.e-popup-content { max-height: 25vh; overflow-y: auto; }

.e-schedule .e-quick-popup-wrapper {
  transform: translate(0, -5%);
}

.e-work-cells.e-disable {
  background-color: #f0f0f0 !important;
  pointer-events: none !important;
}