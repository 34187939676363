@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.intake-comments-mentions.intake-comments-mentions--multiLine .intake-comments-mentions__control,
.project-comments-mentions.project-comments-mentions--multiLine .project-comments-mentions__control {
  height: auto;
  max-height: 6.5rem;
  overflow: hidden;
}


.project-comments-mentions.project-comments-mentions--multiLine .project-comments-mentions__control {
  height: auto;
  max-height: 6.5rem;
  overflow: hidden;
}

.intake-comments-mentions--multiLine .intake-comments-mentions__highlighter,
.project-comments-mentions--multiLine .project-comments-mentions__highlighter {
  min-height: 2.5rem;
  max-height: 6.5rem;
  padding: 0.75rem 1rem;
  overflow: auto !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  transform: translate(-2px, -2px);
}

.intake-comments-mentions--multiLine .intake-comments-mentions__highlighter .intake-comments-mentions__highlighter__substring,
.project-comments-mentions--multiLine .project-comments-mentions__highlighter .project-comments-mentions__highlighter__substring {
  visibility: inherit !important;
  font-weight: inherit;
  @apply text-neutral-black;
}

.intake-comments-mentions .intake-comments-mentions--mention,
.project-comments-mentions .project-comments-mentions--mention {
  @apply text-neutral;
}

.intake-comments-mentions.intake-comments-mentions--multiLine .intake-comments-mentions__control .intake-comments-mentions__input,
.project-comments-mentions--multiLine .project-comments-mentions__control .project-comments-mentions__input {
  min-height: 2.5rem;
  max-height: 6.5rem;
  border-radius: 0.2rem;
  color: transparent;
  caret-color: #0E1212;
  padding: 0.75rem 1rem !important;
  overflow: auto !important;
  @apply border border-neutral-lighter;
}

.intake-comments-mentions.intake-comments-mentions--multiLine .intake-comments-mentions__control .intake-comments-mentions__input:hover,
.intake-comments-mentions.intake-comments-mentions--multiLine .intake-comments-mentions__control .intake-comments-mentions__input:focus,
.project-comments-mentions--multiLine .project-comments-mentions__control .project-comments-mentions__input:hover,
.project-comments-mentions--multiLine .project-comments-mentions__control .project-comments-mentions__input:focus {
  @apply border border-neutral-dark shadow-skim ring-0;
}

.intake-comments-mentions.intake-comments-mentions--multiLine .intake-comments-mentions__control .intake-comments-mentions__input:active,
.project-comments-mentions--multiLine .project-comments-mentions__control .project-comments-mentions__input:active {
  @apply border border-neutral-black shadow-skim ring-0;
}

.intake-comments-mentions.intake-comments-mentions--multiLine .intake-comments-mentions__suggestions {
  transform: translateX(0%) translateY(15%);
}
.project-comments-mentions--multiLine .project-comments-mentions__suggestions {
  transform: translateX(0%) translateY(20%);
}

.intake-comments-mentions.intake-comments-mentions--multiLine .intake-comments-mentions__suggestions__list {
  width: 21rem;
  @apply shadow-lifted;
}

.project-comments-mentions--multiLine .project-comments-mentions__suggestions__list {
  width: 22rem;
  @apply shadow-lifted;
}


/** Mentions V2 */

/** Control Container */
.mention-comment-v2.mention-comment-v2--multiLine {
  height: auto;
}
.mention-comment-v2.mention-comment-v2--multiLine .mention-comment-v2__control {
  height: auto;
  overflow: hidden;
  border: 0;
}

/** Input TextArea */
.mention-comment-v2--multiLine .mention-comment-v2__control .mention-comment-v2__input {
  min-height: 24px;
  max-height: 120px;
  border: 0;
  caret-color: #0E1212;
  overflow: auto !important;
  padding: 0;
}

/** Highlighter Area */
.mention-comment-v2--multiLine .mention-comment-v2__highlighter {
  min-height: 24px;
  max-height: 120px;
  overflow: auto !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  transform: translate(-2px, -2px);
  transition: min-height 0.3s ease;
}

.mention-comment-v2--container:focus-within .mention-comment-v2__input {
  min-height: 75px;
  outline: none;
  box-shadow: none;
}

.mention-comment-v2--container:focus-within .mention-comment-v2__highlighter {
  min-height: 75px;
}

.mention-comment-v2--buttons {
  display: none;
}

.mention-comment-v2--container:focus-within .mention-comment-v2--buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.mention-comment-v2__suggestions {
  transform: translateX(-1.2rem) translateY(15%);
}


.mention-comment-v2__suggestions__list {
  width: 17rem;
  @apply shadow-lifted;
}

.mention-comment-v2-focusable-disabled, .mention-comment-v2-focusable-disabled:hover, .mention-comment-v2-focusable-disabled:focus {
  @apply bg-neutral-lightest !important;
  @apply text-neutral-light !important;
  @apply border-neutral-lightest !important;
  @apply shadow-none !important;
}

:root {
  --scrollbar-size: .375rem;
  --scrollbar-minlength: 1.5rem; /* Minimum length of scrollbar thumb (width of horizontal, height of vertical) */
  --scrollbar-ff-width: thin; /* FF-only accepts auto, thin, none */
  --scrollbar-track-color: transparent;
  --scrollbar-color: rgba(0,0,0,.2);
  --scrollbar-color-hover: rgba(0,0,0,.3);
  --scrollbar-color-active: rgb(0,0,0);
}

.layout-cell {
  overscroll-behavior: contain;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: var(--scrollbar-ff-width);
}

/* This class controls what elements have the new fancy scrollbar CSS */
.layout-scrollbar {
  scrollbar-color: var(--scrollbar-color) var(--scrollbar-track-color);
}
.layout-scrollbar::-webkit-scrollbar {
  height: var(--scrollbar-size);
  width: var(--scrollbar-size);
}
.layout-scrollbar::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}
.layout-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 3px;
}
.layout-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-color-hover);
}
.layout-scrollbar::-webkit-scrollbar-thumb:active {
  background-color: var(--scrollbar-color-active);
}
.layout-scrollbar::-webkit-scrollbar-thumb:vertical {
  min-height: var(--scrollbar-minlength);
}
.layout-scrollbar::-webkit-scrollbar-thumb:horizontal {
  min-width: var(--scrollbar-minlength);
}
