.cardMention textarea,
.modalMention textarea {
  display: none !important;
}

.cardMention div,
.modalMention div {
  border: none !important;
}

.cardMention div {
  display: flex;
  max-width: 100%;
}

.cardMention strong {
  margin-right: 4px;
  white-space: nowrap;
}

.cardMention span,
.modalMention span {
  visibility: visible !important;
  color: #0E1212;
}

.cardMention span:last-child {
  white-space: nowrap;
  height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
