.scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #E1E9EC transparent;
}

.scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
 }

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #E1E9EC;
    border-radius: 4px;
}
